import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconGrid = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="#fff"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <title></title>{' '}
        <g id="Complete">
          {' '}
          <g id="grid">
            {' '}
            <g>
              {' '}
              <rect
                fill="none"
                height="7"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="7"
                x="14.5"
                y="2.5"
              ></rect>{' '}
              <rect
                fill="none"
                height="7"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="7"
                x="14.5"
                y="14.5"
              ></rect>{' '}
              <rect
                fill="none"
                height="7"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="7"
                x="2.5"
                y="2.5"
              ></rect>{' '}
              <rect
                fill="none"
                height="7"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="7"
                x="2.5"
                y="14.5"
              ></rect>{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </g>
    </svg>
  );
};

IconGrid.defaultProps = {
  className: null,
  rootClassName: null,
};

IconGrid.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconGrid;
