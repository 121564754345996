export const RequestStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const DEFAULTS = {
  BOUNDS: {
    _sdkType: 'LatLngBounds',
    ne: {
      _sdkType: 'LatLng',
      lat: 54.4148886,
      lng: -83.35960175,
    },
    sw: {
      _sdkType: 'LatLng',
      lat: -0.85726051,
      lng: -113.39867847,
    },
  },
};
