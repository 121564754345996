import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';

const IconMap = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      width="34px"
      height="34px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {' '}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.525 7.187V16.24C18.525 16.7536 18.305 17.2413 17.9234 17.5738C17.5418 17.9063 17.037 18.0501 16.5428 17.967L14.3539 17.6L9.04605 19L6.3063 18.54C5.48106 18.4018 4.87506 17.6706 4.875 16.813V7.76C4.87499 7.24641 5.09495 6.75874 5.47659 6.42623C5.85823 6.09372 6.36302 5.94995 6.85717 6.033L9.04605 6.4L14.3539 5L17.0937 5.46C17.9189 5.5982 18.5249 6.3294 18.525 7.187Z"
          stroke="#000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        ></path>{' '}
        <path
          d="M9.79602 6.4C9.79602 5.98579 9.46023 5.65 9.04602 5.65C8.63181 5.65 8.29602 5.98579 8.29602 6.4H9.79602ZM8.29602 19C8.29602 19.4142 8.63181 19.75 9.04602 19.75C9.46023 19.75 9.79602 19.4142 9.79602 19H8.29602ZM15.1039 5C15.1039 4.58579 14.7681 4.25 14.3539 4.25C13.9397 4.25 13.6039 4.58579 13.6039 5H15.1039ZM13.6039 17.6C13.6039 18.0142 13.9397 18.35 14.3539 18.35C14.7681 18.35 15.1039 18.0142 15.1039 17.6H13.6039ZM8.29602 6.4V19H9.79602V6.4H8.29602ZM13.6039 5V17.6H15.1039V5H13.6039Z"
          fill="#000"
        ></path>{' '}
      </g>
    </svg>
  );
};

IconMap.defaultProps = {
  className: null,
  rootClassName: null,
};

IconMap.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconMap;
