import React, { Component, createRef } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';
// import component 👇
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
//import styles 👇
import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { isMainSearchTypeKeywords, isOriginInUse, getQueryParamNames } from '../../util/search';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import logoIcon from '../../assets/logo.png';
import { LISTING_STATE_CLOSED, propTypes } from '../../util/types';
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import {
  H3,
  H4,
  H5,
  IconSpinner,
  LayoutSingleColumn,
  Modal,
  ModalInMobile,
  NamedLink,
  OrderPanel,
  OutsideClickHandler,
  Page,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';
import ScrollToTop from 'react-scroll-to-top';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';

import LandingSearchForm from '../LandingPage/LandingSearchForm/LandingSearchForm';
import { moreLinks } from '../LandingPage/SectionMoreLinks';
import IconFilter from '../../components/IconFilter/IconFilter';
import IconGrid from '../../components/IconGrid/IconGrid';
import IconMap from '../../components/IconMap/IconMap';

import css from './SearchPage.module.css';
import { withViewport } from '../../util/uiHelpers';
import { DEFAULTS } from '../../helpers/enums';
import AmentiesFilter from './AmentiesFilter';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
// import component 👇
import Drawer from 'react-modern-drawer';
import './Drawer.css';

import ListingPage, {
  MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
} from '../ListingPage/ListingPageCarousel';
import ActionBarMaybe from '../ListingPage/ActionBarMaybe';
import SectionGallery from '../ListingPage/SectionGallery';
import SectionHeading from '../ListingPage/SectionHeading';
import { richText } from '../../util/richText';
import SectionDetailsMaybe from '../ListingPage/SectionDetailsMaybe';
import SectionTextMaybe from '../ListingPage/SectionTextMaybe';
import { amenitiesIconsData } from '../../helpers/amenitiesHelpers';
import { isArrayLength } from '../../util/genericHelpers';
import SectionReviews from '../ListingPage/SectionReviews';
import SectionMapMaybe from '../ListingPage/SectionMapMaybe';
import SectionAuthorMaybe from '../ListingPage/SectionAuthorMaybe';
import { ensureUser, userDisplayNameAsString } from '../../util/data';
import {
  fetchTimeSlots,
  fetchTransactionLineItems,
  sendInquiry,
  showListing,
} from '../ListingPage/ListingPage.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import SectionRules from '../ListingPage/SectionRules';
import IconCloseCircle from '../../components/IconCloseCircle/IconCloseCircle';
import { handleSubmit } from '../ListingPage/ListingPage.shared';
import { types as sdkTypes } from '../../util/sdkLoader';
import moment from 'moment';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import {
  requestFetchAvailabilityExceptions,
  requestFetchAvailabilityExceptionsWithIntegration,
} from '../EditListingPage/EditListingPage.duck';
import spinner from '../../assets/spinner.svg';
import FilterModal from './FilterModal/FilterModal';
// Define enum for view options
const ViewOptions = {
  LIST: 'list',
  MAP: 'map',
};
const { UUID } = sdkTypes;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === ViewOptions.MAP,
      isMobileModalOpen: false,
      toggleDrawer: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      filterDrawer: false,
      currentView: ViewOptions.LIST,
      activeTitle: null,
      sidebarSytle: undefined,
      listingId: null,
      roomsFilter: null,
      listing: null,
      showContent: false,
    };

    this.sidebarRef = createRef(null);
    this.scrollableDivRef = createRef(null);

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
    this.onViewListing = this.onViewListing.bind(this);
    this.onSetListing = this.onSetListing.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.onSubmitOrder = this.onSubmitOrder.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
    this.isLoading = false;

    // SortBy
    this.onChangeSearchView = this.onChangeSearchView.bind(this);
    this.onChangeBoolean = this.onChangeBoolean.bind(this);
    this.openFiltersDrawer = this.openFiltersDrawer.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onRoomsFilterChange = this.onRoomsFilterChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const { viewport } = this.props;
    const isMobileLayout = viewport.width < MODAL_BREAKPOINT;
    if (!isMobileLayout) {
      if (this?.sidebarRef?.current?.offsetTop > 0 && !this?.state?.sidebarSytle) {
        this.setState({ sidebarSytle: { height: 'calc(100vh - 80px)', overflowY: 'auto' } });
      }
      if (this?.sidebarRef?.current?.offsetTop < 50 && this?.state?.sidebarSytle != undefined) {
        this.setState({ sidebarSytle: undefined });
      }
    }
  }

  disableScroll = () => {
    // disableBodyScroll(this.scrollableDivRef.current);
  };

  onSubmitOrder = () => {
    // disableBodyScroll(this.scrollableDivRef.current);
  };

  enableScroll = () => {
    // enableBodyScroll(this.scrollableDivRef.current);
  };

  onChangeSearchView(type) {
    this.setState({ currentView: type });
  }

  onChangeBoolean(boolean) {
    this.setState({ toggleDrawer: boolean });
  }
  onRoomsFilterChange(roomsFilter) {
    // Iterate over the keys of the roomsFilter object
    for (const key in roomsFilter) {
      // Check if the value of the current key is 'Any'
      if (roomsFilter[key] === 'Any') {
        // If it is 'Any', delete the key from the object
        delete roomsFilter[key];
      }
    }
    this.setState({ roomsFilter });
  }

  onChangeActiveClass(title) {
    this.setState({ activeTitle: title });
  }

  openFiltersDrawer(boolean) {
    this.setState({ filterDrawer: boolean });
    if (!boolean) {
      this.enableScroll();
    } else {
      this.disableScroll();
    }
  }

  componentWillUnmount() {
    // 5. Useful if we have called disableBodyScroll for multiple target elements,
    // and we just want a kill-switch to undo all that.
    // OR useful for if the `hideTargetElement()` function got circumvented eg. visitor
    // clicks a link which takes him/her to a different page within the app.
    clearAllBodyScrollLocks();
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  async onViewListing(id) {
    const {
      onShowListing,
      config,
      isAuthenticated,
      currentUser,
      onFetchTimeSlots,
      onFetchAvailabilityExceptions,
      onFetchAvailabilityExceptionsWithIntegration,
    } = this.props;

    // Check if onViewListing is already running
    if (this.isLoading) {
      return;
    }

    this.setState({ listingId: id });
    this.isLoading = true;

    if (id) {
      const listingId = new UUID(id);

      try {
        const listingResponse = await onShowListing(listingId, config);
        const listing = listingResponse.data.data;
        this.onSetListing(listing);
        const isOwnListing = listing?.author?.id?.uuid === currentUser?.id?.uuid;
        // Replace fetchMonthlyTimeSlots with your code
        const hasWindow = typeof window !== 'undefined';
        const attributes = listing.attributes;
        const hasTimeZone =
          attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone
            ? attributes.availabilityPlan.timezone
            : defaultTimeZone();

        if (hasWindow && listing.id && hasTimeZone) {
          const generateDateBoundaries = months => {
            const nextBoundary = moment()
              .add(months, 'months')
              .toDate();
            const nextAfterNextMonth = moment()
              .add(months + 2, 'months')
              .toDate();
            return [nextBoundary, nextAfterNextMonth];
          };

          const monthsToFetch = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

          const promises = monthsToFetch.map(months => {
            const [nextBoundary, nextAfterNextMonth] = generateDateBoundaries(months);
            return onFetchTimeSlots(listing.id, nextBoundary, nextAfterNextMonth, hasTimeZone);
          });

          const exceptionParams = {
            listingId: listing?.id,
            start: moment()
              .startOf('day')
              .toDate(),
            end: moment()
              .add(360, 'days')
              .startOf('day')
              .toDate(),
          };

          onFetchAvailabilityExceptionsWithIntegration({ ...exceptionParams });
          // if (isOwnListing && isAuthenticated) {
          // } else {
          //   onFetchAvailabilityExceptionsWithIntegration({ ...exceptionParams });
          // }

          await Promise.all(promises);
        }
      } catch (error) {
        // Handle error appropriately
      }
    }

    this.isLoading = false;
    this.contentTimeout = setTimeout(() => {
      this.setState({ showContent: true });
    }, 1500);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the button click event occurred
    if (prevState.listingId !== this.state.listingId) {
      this.onViewListing(this.state.listingId);
    }
  }

  onSetListing(listing) {
    const { getListing } = this.props;
    const listingCombined = getListing(listing?.id);
    this.setState({ listing: listingCombined });
  }

  componentWillUnmount() {
    // Clear the timeout to prevent setting state on an unmounted component
    clearTimeout(this.contentTimeout);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );
    this.openFiltersDrawer(false);

    if (this.state.roomsFilter) {
      const filterMap = {
        beds: 'pub_beds',
        bedrooms: 'pub_bedrooms',
        bathrooms: 'pub_bathrooms',
      };

      Object.entries(filterMap).forEach(([key, searchKey]) => {
        if (this.state.roomsFilter[key]) {
          search[searchKey] = this.state.roomsFilter[key];
        }
      });
    }

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.openFiltersDrawer(false);
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });
    this.openFiltersDrawer(false);
    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      history,
      viewport,
      currentUser,
      callSetInitialValues,
      reviews,
      getListing,
      fetchReviewsError,
      showListingError,
      monthlyTimeSlots,
      sendInquiryInProgress,
      sendInquiryError,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      inquiryModalOpenForListingId,
      onFetchTimeSlots,
      onFetchTransactionLineItems,
      onInitializeCardPaymentData,
      allListings,
    } = this.props;

    const isTabLayout = viewport.width > 0 && viewport.width < 1024;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    // const [customSecondaryFilters] = groupListingFieldConfigs(
    //   listingFieldsConfig,
    //   activeListingTypes
    // );
    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );

    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const { bounds, origin } = searchParamsInURL || {};

    const { schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    const handleSearchSubmit = values => {
      delete values?.pets;
      const { location = {}, dates, ...rest } = values;
      const { search, selectedPlace = {} } = location;
      const { bounds } = selectedPlace;
      const searchQuery = {
        address: search,
        dates,
        bounds,
        search,
        ...rest,
      };

      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
    };

    const iconData = [
      { view: ViewOptions.LIST, icon: <IconGrid className={css.gridIcon} /> },
      { view: ViewOptions.MAP, icon: <IconMap className={css.mapIcon} /> },
    ];

    const currentListing = this.state.listing;

    const authorAvailable = currentListing && currentListing?.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing?.author?.id?.uuid === currentUser?.id?.uuid;

    const listingId = currentListing?.id;
    const listingSlug = createSlug(currentListing?.attributes?.title || '');

    const isDraftVariant = currentListing?.attributes?.state === 'draft';

    const listingPathParamType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;

    const listingTab = isDraftVariant ? 'photos' : 'details';

    const listingConfig = config.listing;

    const {
      description = '',
      geolocation = null,
      price = null,
      title = '',
      publicData = {},
      metadata = {},
    } = currentListing?.attributes || {};

    const richTitle = (
      <span>
        {richText(title, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
          longWordClass: css.longWord,
        })}
      </span>
    );

    const amenitiesData = amenitiesIconsData.filter(e => {
      return isArrayLength(publicData?.amenities) && publicData.amenities.includes(e.value);
    });

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const commonParams = { history, routes: routeConfiguration };

    const onSubmit = handleSubmit({
      ...commonParams,
      params: {
        id: currentListing?.id?.uuid,
      },
      currentUser,
      callSetInitialValues,
      getListing,
      onInitializeCardPaymentData,
    });

    const handleOrderSubmit = values => {
      const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
      if (currentListing?.id?.uuid) {
        if (isOwnListing || isCurrentlyClosed) {
          window.scrollTo(0, 0);
        } else {
          onSubmit(values);
        }
      }
    };

    const drawer = (
      <Drawer
        className={css.listingDrawer}
        duration={300}
        lockBackgroundScroll={true}
        open={!!this.state.listingId}
        onClose={() => this.setState({ listingId: null, listing: null })}
        direction={isMobileLayout ? 'bottom' : 'right'}
      >
        {!this.state.showContent || !currentListing ? (
          <div className={css.listingLoaders}>
            <div className={css.loaderContainer}>
              <div className={css.logo}>
                <img src={logoIcon} alt="BnBunite" />
              </div>
              <img src={spinner} alt="spinner" />
              {/* <div className={css.defaultLoader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div> */}
            </div>
          </div>
        ) : (
          <>
            {' '}
            {isMobileLayout ? (
              <span
                className={css.closeDrawer}
                onClick={() => this.setState({ listingId: null, listing: null })}
              >
                <IconCloseCircle />
              </span>
            ) : null}
            <div className={css.contentWrapperForProductLayout}>
              <div className={css.mainColumnForProductLayout}>
                {currentListing?.id ? (
                  <ActionBarMaybe
                    className={css.actionBarForProductLayout}
                    isOwnListing={isOwnListing}
                    listing={currentListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingPathParamType,
                      tab: listingTab,
                    }}
                  />
                ) : null}
                <SectionGallery
                  listing={currentListing}
                  variantPrefix={config.layout.listingImage.variantPrefix}
                  viewport={viewport}
                />
                <div className={css.mainContentWrapper}>
                  <div className={css.mainContentLeft}>
                    <SectionHeading
                      id={currentListing?.id?.uuid}
                      setIsSocialSharingModalOpen={() => {}}
                      richTitle={richTitle}
                      publicData={publicData}
                    />
                    <SectionDetailsMaybe
                      publicData={publicData}
                      metadata={metadata}
                      currentListing={currentListing}
                      listingConfig={listingConfig}
                      intl={intl}
                    />
                    {/* <SectionTextMaybe text={description} showAsIngress /> */}
                    <div className={css.amenitiesProvided}>
                      <h2 className={css.sectionHeading}>Amenities</h2>
                      <div className={css.amenitiesData}>
                        {amenitiesData?.map(a => (
                          <div className={css.amenity}>
                            <span className={css.icon}>{a?.icon}</span>{' '}
                            <span className={css.label}>{a?.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={css.amenitiesProvided}>
                      <h2 className={css.sectionHeading}>Additional Amenities</h2>
                      <div>{publicData?.additionalAmenities}</div>
                    </div>
                    <div className={css.amenitiesProvided}>
                      <h2 className={css.sectionHeading}>Additional Features</h2>
                      <div>{publicData?.additionalFeatures}</div>
                    </div>
                    <SectionReviews
                      reviews={reviews}
                      fetchReviewsError={fetchReviewsError}
                      intl={intl}
                    />
                    <SectionMapMaybe
                      geolocation={geolocation}
                      publicData={publicData}
                      listingId={currentListing?.id}
                      mapsConfig={config.maps}
                    />
                    <SectionAuthorMaybe
                      title={title}
                      listing={currentListing}
                      authorDisplayName={authorDisplayName}
                      onContactUser={() => {}}
                      isInquiryModalOpen={false}
                      onCloseInquiryModal={() => {}}
                      sendInquiryError={sendInquiryError}
                      sendInquiryInProgress={sendInquiryInProgress}
                      onSubmitInquiry={() => {}}
                      currentUser={currentUser}
                      reviews={reviews}
                      onManageDisableScrolling={onManageDisableScrolling}
                      isListingPageAuthorCard={true}
                    />
                  </div>
                  <div className={css.mainContentRight}>
                    <OrderPanel
                      className={css.productOrderPanel}
                      listing={currentListing}
                      isOwnListing={isOwnListing}
                      onSubmit={handleOrderSubmit}
                      onCloseDrawer={() => this.setState({ listingId: null, listing: null })}
                      authorLink={
                        <NamedLink
                          className={css.authorNameLink}
                          name="ListingPage"
                          params={{
                            id: listingId?.uuid,
                            slug: listingSlug,
                          }}
                          to={{ hash: '#author' }}
                        >
                          {authorDisplayName}
                        </NamedLink>
                      }
                      title={
                        <FormattedMessage
                          id="ListingPage.orderTitle"
                          values={{ title: richTitle }}
                        />
                      }
                      titleDesktop={
                        <H4 as="h1" className={css.orderPanelTitle}>
                          <FormattedMessage
                            id="ListingPage.orderTitle"
                            values={{ title: richTitle }}
                          />
                        </H4>
                      }
                      author={ensuredAuthor}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onContactUser={() => {}}
                      monthlyTimeSlots={monthlyTimeSlots}
                      onFetchTimeSlots={onFetchTimeSlots}
                      onFetchTransactionLineItems={onFetchTransactionLineItems}
                      lineItems={lineItems}
                      fetchLineItemsInProgress={fetchLineItemsInProgress}
                      fetchLineItemsError={fetchLineItemsError}
                      marketplaceCurrency={config.currency}
                      dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                      marketplaceName={config.marketplaceName}
                    />
                  </div>
                </div>
                <SectionRules isTabLayout={isTabLayout} title={title} publicData={publicData} />
              </div>
              {/* <SocialSharing
            onManageDisableScrolling={onManageDisableScrolling}
            isSocialSharingModalOpen={isSocialSharingModalOpen}
            onOpenShareModal={() => setIsSocialSharingModalOpen(false)}
            location={location}
            listing={currentListing}
          /> */}
            </div>
          </>
        )}
      </Drawer>
    );

    const filterModal = (
      <FilterModal
        id="SearchPageWithMap.filterModal"
        isOpen={this.state.filterDrawer}
        onClose={() => this.openFiltersDrawer(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        modalTitle="Filters"
      >
        <div>
          <SearchFiltersSecondary
            urlQueryParams={validQueryParams}
            listingsAreLoaded={listingsAreLoaded}
            applyFilters={this.applyFilters}
            cancelFilters={this.cancelFilters}
            resetAll={this.resetAll}
            onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
          >
            {[
              isArrayLength(defaultFiltersConfig) &&
                defaultFiltersConfig.find(d => d?.key === 'price'),
              ...listingFieldsConfig,
            ]
              .filter(e => e.key !== 'type')
              .filter(e => e.key !== 'category')
              .map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${config.key}`}
                    idPrefix="SearchFiltersSecondary"
                    config={config}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    onFilterChange={this.onRoomsFilterChange}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    showAsPopup={false}
                  />
                );
              })}
          </SearchFiltersSecondary>
        </div>
      </FilterModal>
    );

    return (
      <Page scrollingDisabled={false} description={description} title={title} schema={schema}>
        <LayoutSingleColumn
          className={css.pageRoot}
          isAuthPage
          topbar={<TopbarContainer isSearchPage={true} viewport={viewport} isWhiteBgNav={true} />}
        >
          <div className={css.searchPageWrapper}>
            <div className={css.filterBar}>
              <div className={css.showHideMap}>
                {iconData.map(item => (
                  <span
                    key={item.view}
                    className={classNames(css.icon, {
                      [css.active]: this.state.currentView === item.view,
                    })}
                    onClick={() => this.onChangeSearchView(item.view)}
                  >
                    {item.icon}
                  </span>
                ))}
              </div>
              <LandingSearchForm
                rootClassName={css.landingSearchForm}
                onSubmit={handleSearchSubmit}
                intl={intl}
                isSearchPageFilter={true}
                viewport={viewport}
              />
              <div
                className={classNames(css.filterIcon, {
                  [css.opened]: this.state.filterDrawer,
                })}
                onClick={() => this.openFiltersDrawer(true)}
              >
                <IconFilter /> Filters
              </div>
            </div>
            <div className={css.container}>
              <AmentiesFilter
                ref={this.sidebarRef}
                activeTitle={this.state.activeTitle}
                sidebarSytle={this.state.sidebarSytle}
                onChangeActiveClass={this.onChangeActiveClass}
                onChangeBoolean={this.onChangeBoolean}
              />
              <div
                ref={this.scrollableDivRef}
                className={classNames(css.searchResultContainer, {
                  [css.mapView]: this.state.currentView === ViewOptions.MAP,
                })}
              >
                <div
                  className={classNames(css.listingsForMapVariant, {
                    [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                  })}
                >
                  {searchListingsError ? (
                    <H3 className={css.error}>
                      <FormattedMessage id="SearchPage.searchError" />
                    </H3>
                  ) : null}
                  <div className={css.searchInProgress}>
                    {searchInProgress ? (
                      <IconSpinner />
                    ) : listings?.length === 0 && !searchInProgress ? (
                      <>
                        <H3 className={css.noResultsFound}>
                          <FormattedMessage id="SearchPage.noResultsFound" />
                        </H3>
                        <NamedLink name="SearchPage" className={css.goToSearchPageLink}>
                          {' '}
                          <FormattedMessage id="SearchPage.clearFilter" />
                        </NamedLink>
                      </>
                    ) : null}
                  </div>
                  <SearchResultsPanel
                    className={css.searchListingsPanel}
                    listings={isArrayLength(listings) ? listings : allListings}
                    pagination={listingsAreLoaded ? pagination : null}
                    search={parse(location.search)}
                    setActiveListing={onActivateListing}
                    config={config}
                    isMapVariant
                    isMapViewOpen={this.state.currentView === ViewOptions.MAP}
                    isMobileLayout={isMobileLayout}
                    history={history}
                    onViewListing={this.onViewListing}
                    onSetListing={this.onSetListing}
                  />
                </div>
                <ModalInMobile
                  className={css.mapPanel}
                  id="SearchPage.map"
                  isModalOpenOnMobile={true}
                  onClose={() => this.setState({ currentView: ViewOptions.LIST })}
                  showAsModalMaxWidth={MODAL_BREAKPOINT}
                  onManageDisableScrolling={onManageDisableScrolling}
                >
                  <div className={css.mapWrapper} data-testid="searchMapContainer">
                    <SearchMap
                      reusableContainerClassName={css.map}
                      activeListingId={activeListingId}
                      bounds={bounds || DEFAULTS.BOUNDS}
                      center={origin}
                      isSearchMapOpenOnMobile={true}
                      location={location}
                      isSearchPage
                      listings={listings || []}
                      onMapMoveEnd={this.onMapMoveEnd}
                      onViewListing={this.onViewListing}
                      onCloseAsModal={() => {
                        onManageDisableScrolling('SearchPage.map', false);
                      }}
                      messages={intl.messages}
                    />
                  </div>
                </ModalInMobile>
              </div>
            </div>
          </div>
          {filterModal}
          {drawer}
          <ScrollToTop smooth color="#3e5ba6" />
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    customPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  } = state.SearchPage;
  const { isAuthenticated } = state.auth;

  const listings = getListingsById(state, currentPageResultIds);
  const allListings = getListingsById(state, customPageResultIds);
  const { currentUser } = state.user;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  listings.sort((a, b) => {
    const titleA = a.attributes.title.toUpperCase(); // to make it case-insensitive
    const titleB = b.attributes.title.toUpperCase(); // to make it case-insensitive

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  return {
    listings,
    allListings,
    pagination,
    getListing,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onShowListing: (listingId, config) => dispatch(showListing(listingId, config)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchAvailabilityExceptionsWithIntegration: params =>
    dispatch(requestFetchAvailabilityExceptionsWithIntegration(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport
)(EnhancedSearchPage);

export default SearchPage;
