import React, { forwardRef } from 'react';
import css from './SearchPage.module.css';
import { moreLinks } from '../LandingPage/SectionMoreLinks';
import { NamedLink } from '../../components';
import classNames from 'classnames';

const AmenitiesFilter = forwardRef((props, ref) => {
  const { sidebarStyle, activeTitle, onChangeActiveClass, onChangeBoolean } = props;

  moreLinks.sort((a, b) => {
    const titleA = a.title.toUpperCase(); // to make it case-insensitive
    const titleB = b.title.toUpperCase(); // to make it case-insensitive

    if (titleA < titleB) {
      return -1;
    }
    if (titleA > titleB) {
      return 1;
    }
    return 0;
  });

  return (
    <div
      ref={ref}
      className={css.sidebarAmenities}
      onMouseEnter={() => onChangeBoolean(true)}
      onMouseLeave={() => onChangeBoolean(false)}
      style={sidebarStyle}
    >
      {moreLinks.map((l, i) => (
        <NamedLink
          key={i}
          name="SearchPage"
          to={{ search: l.search }}
          className={classNames(css.amenityBlock, {
            [css.active]: l.title === activeTitle,
          })}
          onMouseEnter={() => onChangeActiveClass(l.title)}
        >
          <div className={classNames(css.placeIcon, l.title)}>{l.icon}</div>
          <span className={css.placeName}> {l.title}</span>
        </NamedLink>
      ))}
    </div>
  );
});

export default AmenitiesFilter;
